<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          <b v-if="statuscleartrash === 'multi'">{{ $t("trashPage.messageboxheader") }}</b
          >
          <b v-else>{{$t("trashPage.cleartrashfilefolder")}}</b
          ><v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">delete</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text v-if="statuscleartrash === 'multi'">
          <v-icon color="red">folder</v-icon>
          {{$t("trashPage.confrimmuti")}}
        </v-card-text>
        <v-card-text v-else>
          <v-icon color="red">folder</v-icon>
          {{$t("trashPage.confrimdeleteall1")}}
        </v-card-text>
        <br />
        <v-card-text>
          <v-icon color="amber">error_outline</v-icon>
          <b>{{$t("trashPage.confrimdeleteall2")}}</b>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="color.theme"
            @click="$emit('closedialog')"
            outlined
            :disabled="processdelete"
            >{{ $t("trashPage.canceldelete") }}</v-btn
          >
          <v-btn
            v-if="statuscleartrash === 'multi'"
            color="red  darken-1 white--text"
            depressed
            @click="$emit('multidelete')"
            :loading="processdelete"
            >{{ $t("trashPage.forcedelete") }}</v-btn
          >
          <v-btn
            v-else
            color="red  darken-1 white--text"
            depressed
            @click="$emit('cleartrash')"
            :loading="processdelete"
            >{{ $t("balloon.cleartrash") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  data() {
    return {
      loaddataprogress: false,
      password: "",
      showpassword: false,
    };
  },
  props: ["show", "success", "processdelete", "statuscleartrash"],
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "font-size: 16px; line-height: 24px;";
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // style อักษรแสดงการลบเสร็จสิ้น ของ mobile
    deletesuccess() {
      return "color:#58A144; font-size: 18px; line-height: 24px;";
    },
    // style อักษร header ของ mobile
    headerPageDialog() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
  },
};
</script>
<style></style>
